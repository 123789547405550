<template>
  <app-header />
  <div class="container">
    <h1>Page Not Found</h1>
  </div>
</template>

<script>
import AppHeader from "../components/Header.vue";

export default {
  components: {
    AppHeader
  }
};
</script>
